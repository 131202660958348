import styled from 'styled-components';
import Table from 'components/table';

export const ScContainer = styled.div`
  padding: 1.5em;
`;

export const ScButtonsContainer = styled.div`
  margin-top: 1.875em;
`;

export const ScTable = styled(Table)`
  margin-bottom: 1.875em;

  &&& {
    .cell__description {
      white-space: pre-wrap;
    }
  }
`;
