import styled from 'styled-components';
import { Button } from 'components/button';
import { PrinterFilled, ReloadOutlined } from '@ant-design/icons';

export const ScFooterContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const ScButton = styled(Button)`
  .ant-btn-loading-icon {
    position: absolute;
    left: 1em;
  }
`;

export const ScButtonReload = styled.button`
  position: absolute;
  z-index: 1;
  top: -10px;
  left: -10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.highlightColor};
  color: ${({ theme }) => theme.highlightColor_contrast};

  &.loading {
    animation: spin 2s linear infinite;
  }
  &[disabled] {
    background-color: ${({ theme }) => theme.disabledSurfaceColor};
    color: ${({ theme }) => theme.disabledSurfaceColor_contrast};
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ScDocumentButtonIcon = styled(PrinterFilled)`
  font-size: 1.6em;
  margin-right: 0.5em;
`;

export const ScDocumentReloadButtonIcon = styled(ReloadOutlined)`
  font-size: 0.7rem;
`;
