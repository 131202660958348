import React, { FC } from 'react';
import { CommentsModal, CommentsModalProps } from './CommentsModal';
import { EditPriceModal, EditPriceModalProps } from './EditPriceModal/EditPriceModal';
import { DiscountModal, DiscountModalProps } from './DiscountModal';
import { DocumentModal, DocumentModalProps } from './DocumentModal';
import { PackageLinesModal, PackageLinesModalProps } from './PackageLinesModal';
import { CreateOptionModal, CustomOptionModalProps } from './CreateOptionModal';
import { RulesModal, RulesModalProps } from './RulesModal/RulesModal';
import { MakeOptionalModal, MakeOptionalModalProps } from './MakeOptionalModal';

interface Props {
  commentsModalState: CommentsModalProps | undefined;
  editPriceModalState: EditPriceModalProps | undefined;
  discountModalState: DiscountModalProps | undefined;
  documentsModalState: DocumentModalProps | undefined;
  packageLinesModalState: PackageLinesModalProps | undefined;
  createOptionModalState: CustomOptionModalProps | undefined;
  obligatoryModalState: RulesModalProps | undefined;
  makeOptionalModalState: MakeOptionalModalProps | undefined;
}
const DynamicPageModals: FC<Props> = ({
  commentsModalState,
  editPriceModalState,
  discountModalState,
  documentsModalState,
  packageLinesModalState,
  createOptionModalState,
  obligatoryModalState,
  makeOptionalModalState,
}) => {
  return (
    <>
      {commentsModalState && <CommentsModal {...commentsModalState} />}
      {editPriceModalState && <EditPriceModal {...editPriceModalState} />}
      {discountModalState && <DiscountModal {...discountModalState} />}
      {documentsModalState && <DocumentModal {...documentsModalState} />}
      {packageLinesModalState && <PackageLinesModal {...packageLinesModalState} />}
      {createOptionModalState && <CreateOptionModal {...createOptionModalState} />}
      {obligatoryModalState && <RulesModal {...obligatoryModalState} />}
      {makeOptionalModalState && <MakeOptionalModal {...makeOptionalModalState} />}
    </>
  );
};

export default DynamicPageModals;
