import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableColumn } from 'components/table';
import { StockMachine, StockMachineOptionFields } from 'types/vendor';
import { FeatureFlagColumnSettings } from 'types';

enum ColumnKey {
  ID = StockMachineOptionFields.ID,
  DESCRIPTION = StockMachineOptionFields.description,
  QUANTITY = StockMachineOptionFields.quantity,
}

export default function useColumns(): TableColumn<StockMachine>[] {
  const { t } = useTranslation();

  const keyToColumn: Record<
    string,
    (settings?: FeatureFlagColumnSettings) => ColumnType<StockMachine>
  > = useMemo(() => {
    return {
      [ColumnKey.ID]: () => ({
        title: t('PRICE_LIST_STOCK_OPTIONS_MODAL_COLUMN_ID'),
        dataIndex: ColumnKey.ID,
        key: ColumnKey.ID,
        maxWidth: '5rem',
      }),
      [ColumnKey.DESCRIPTION]: () => ({
        title: t('PRICE_LIST_STOCK_OPTIONS_MODAL_COLUMN_DESCRIPTION'),
        dataIndex: ColumnKey.DESCRIPTION,
        key: ColumnKey.DESCRIPTION,
        maxWidth: '20rem',
      }),
      [ColumnKey.QUANTITY]: () => ({
        title: t('PRICE_LIST_STOCK_OPTIONS_MODAL_COLUMN_QUANTITY'),
        dataIndex: ColumnKey.QUANTITY,
        key: ColumnKey.QUANTITY,
        maxWidth: '5rem',
      }),
    };
  }, [t]);

  const columns = useMemo<ColumnType<StockMachine>[]>(() => {
    return [{ name: 'CDopt' }, { name: 'INopt' }, { name: 'CNopt' }].reduce<
      ColumnType<StockMachine>[]
    >((acc, setting) => {
      const column = keyToColumn[setting.name]?.();
      if (column) acc.push(column);
      return acc;
    }, []);
  }, [keyToColumn]);

  return columns;
}
