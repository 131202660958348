import React from 'react';
import styled, { css } from 'styled-components';
import { Collapse } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as Swap } from '../../assets/icons/swap.svg';
import CopyIcon from '@ant-design/icons/CopyOutlined';

export const ScCustomerForm = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.formHeader};
`;

export const ScItemWrap = styled.div`
  padding: 2rem;
  width: 100%;
  > button {
    flex-basis: 14em;

    &:first-child {
      margin-right: 1em;
    }
  }
`;

export const ScTabContainer = styled.div`
  margin: 2em;
  margin-top: 0;

  > button {
    flex-basis: 14em;

    &:first-child {
      margin-right: 1em;
    }
  }
`;

export const ScTabContainerNested = styled.div`
  margin: 1.75em 0;
  margin-top: 1em;

  > button {
    flex-basis: 14em;

    &:first-child {
      margin-right: 1em;
    }
  }
`;

export const ScCollapse = styled(Collapse)`
  &.ant-collapse {
    .ant-collapse-item {
      flex-direction: column;
      margin-bottom: 1.25em;
      border-left: 0;
      border-right: 0;

      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }

      .ant-collapse-header {
        border: 1px solid ${({ theme }) => theme.colors.softPeach};
        box-shadow: ${({ theme }) => theme.shadows.formHeader};
        padding-left: 3.75em;
        font-size: 0.875em;

        .ant-collapse-extra {
          position: absolute;
          left: 1.5em;
          top: 0.75em;
          cursor: pointer;
          z-index: 10;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-bottom: 0;
        }
      }
    }
  }
`;

const SwapIcon = props => <Icon component={Swap} {...props} />;

const iconStyles = css`
  cursor: pointer;
  margin-left: 1em;

  /* Question of overriding */
  && {
    margin-right: unset;
  }

  &:hover {
    svg {
      > path {
        fill: ${({ theme }) => theme.mainColor};
      }
    }
  }

  &[disabled] {
    pointer-events: none;
    svg {
      > path {
        fill: ${({ theme }) => theme.colors.dustyGray};
      }
    }
  }
`;

export const ScSwapIcon = styled(SwapIcon)`
  ${iconStyles}
`;

export const ScCopyIcon = styled(CopyIcon)`
  ${iconStyles}
`;
