import { ModelRecordFields, ModelRecord } from 'types/vendor';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useColumns = (): ColumnsType<ModelRecord> => {
  const { t } = useTranslation();

  return [
    {
      title: t('TABLE_ITEM_ID'),
      key: 'key',
      width: '9em',
      render: (value, record) => <>{record[ModelRecordFields.ID]}</>,
    },
    {
      title: t('DESCRIPTION'),
      width: '24em',
      dataIndex: ModelRecordFields.name,
      className: 'cell__description',
    },
  ];
};
