import React, { FC, useMemo } from 'react';
import { TotalTableRow } from '../components/TotalTableRow';
import {
  ModelMachineConfigurationFields,
  TotalsItem,
} from '@hypercharge/xdms-client/lib/types';
import { TotalPageAdaptedFields } from '../utils';
import { Price } from 'components/Price';
import { get } from 'utils';
import { TFunction } from 'i18next';
import { ScTotalEditableField, ScTotalTableInput } from './BaseMachine.styles';
import { useTotalPage } from '../TotalPageProvider';
import { FeatureSource, useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { modelSelectors } from 'store';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

interface Props {
  totalData: TotalsItem | null;
  FIELDS: TotalPageAdaptedFields;
  t: TFunction;
  hideZeroDiscounts: boolean;
}

const BaseMachine: FC<Props> = ({ totalData, FIELDS, t, hideZeroDiscounts }) => {
  const { updateBaseModelPrice, machineConfiguration } = useTotalPage();
  const { isFeatureEnabled } = useFeature();

  const { isConfigurationComplete } = useSelector(modelSelectors.getVariables);

  const isAllowTotalBaseModelPriceEditFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalBaseModelPriceEdit,
    source: FeatureSource.GLOBAL,
  });

  const isEditEnabled = useMemo(() => {
    return isAllowTotalBaseModelPriceEditFeatureEnabled && !isConfigurationComplete;
  }, [isAllowTotalBaseModelPriceEditFeatureEnabled, isConfigurationComplete]);

  const discountAmount = get(totalData, FIELDS.baseMachine.discountAmount, 0);
  const discountPercent = get(totalData, FIELDS.baseMachine.discountPercent, 0);

  const showDiscountAmount = Boolean(!hideZeroDiscounts || discountAmount);
  const showDiscountPercent = Boolean(!hideZeroDiscounts || discountPercent);

  const isBasePriceChanged = get(
    machineConfiguration,
    ModelMachineConfigurationFields.isBasePriceChanged,
    false,
  );

  return (
    <TotalTableRow
      data-testid="total-page-base-machine"
      borderBottom="thin"
      shiftFirstColumn
      shadeNumbers
      fields={[
        t('TOTAL_HEADER_BASE_MACHINE'),
        undefined,
        undefined,
        undefined,
        isEditEnabled ? (
          <Tooltip
            title={
              <>
                {t('TOTAL_HEADER_BASE_MACHINE_INITIAL')}
                <Price
                  style={{ display: 'inline-block' }}
                  value={get(totalData, FIELDS.baseMachine.initial, 0)}
                />
              </>
            }
          >
            <ScTotalEditableField
              $isEdited={isBasePriceChanged}
              component={ScTotalTableInput}
              value={get(totalData, FIELDS.baseMachine.brutto, 0)}
              formatValueForStaticView={value => <Price value={Number(value)} />}
              onChange={value => {
                updateBaseModelPrice(Number(value));
              }}
              componentProps={{
                step: 1,
                size: 'small',
              }}
            />
          </Tooltip>
        ) : (
          <Price value={get(totalData, FIELDS.baseMachine.brutto, 0)} />
        ),
        <>{showDiscountAmount && <Price value={discountAmount} />}</>,
        <>
          {showDiscountPercent && (
            <Price value={discountPercent} percentage priceStyle="decimal" />
          )}
        </>,
        <Price key={'1'} value={get(totalData, FIELDS.baseMachine.netto, 0)} />,
      ]}
    />
  );
};

export default BaseMachine;
