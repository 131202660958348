import styled from 'styled-components';

// extracted these styles from 'components/table/styles'
// as they are being used as separate layout build set
// and weren't been used in table

const table = styled.table<{ $flat?: boolean; $fixed?: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.softPeach};
  border-collapse: collapse;
  th,
  td {
    padding: 0.75rem;
    min-height: 3.75rem;
    border: 1px solid ${({ theme }) => theme.colors.softPeach};
  }

  ${({ $fixed }) => {
    if (!$fixed) return '';
    return `
      table-layout:fixed;
      td {
        overflow: hidden;
      }
    `;
  }}

  ${({ $flat, theme }) => {
    if (!$flat) return '';
    return `
      font-weight: normal;
      border: none;
      th {
        font-weight: 500;
      }
      th,
      td {
        padding: 1.375rem;
        border: none;
        border-bottom: 1px solid ${theme.colors.whisper};
      }
    `;
  }}
  & div .ant-input.ant-input {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 0.875em;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-input-number {
    border: none;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;

    input {
      padding: 0;
      font-size: 1em;
      font-weight: 600;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const colgroup = styled.colgroup``;
const col = styled.col``;
const tr = styled.tr``;
const th = styled.th``;
const td = styled.td``;

export const TableLike = Object.assign(table, { td, tr, th, colgroup, col });
