import {
  Model,
  ModelPackageLine,
  ModelPackageLineFields,
  Model_CommonItem,
  Model_CommonItemFields,
  PackageItem,
} from '@hypercharge/xdms-client/lib/types';
import { RadioChangeEvent, Typography } from 'antd';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import Checkbox from 'components/form/Checkbox';
import { Modal } from 'components/modal';
import { useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import { PAGES_SETTINGS } from 'context/tableCategories/TableCategoriesProvider';
import { isPackage, isPackageLine } from 'pages/DynamicPage/utils';
import { getPackageLines } from 'pages/DynamicPage/utils/get-package-lines';
import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { OptionWithKey, ScrollMenuOptions } from 'types/common';
import { get, pick } from 'utils';
import { defaultStepId, NAME_TAB_ALL } from 'utils/constants';
import { getModelUpdateAttributes } from 'utils/format';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import {
  ScModalFooter,
  ScModalFooterButton,
  ScModalTitle,
} from 'components/modalLike.styles';
import { ScCheckboxWrapper } from './EditPriceModal.styles';
import { TableLike } from 'components/tableLike.styles';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import FormField from 'components/form/formik/FormField';
import { Input } from 'components/form/Input';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';
import Select from 'components/form/Selector';

type FormDataItem = Pick<
  Model_CommonItem,
  | Model_CommonItemFields.ID
  | Model_CommonItemFields.name
  | Model_CommonItemFields.price
  | Model_CommonItemFields.categoryCode
  | Model_CommonItemFields.purchasePrice
> & {
  disabled: boolean;
};
type FormData = {
  rows: FormDataItem[];
};

enum Mode {
  Package = 'package',
  PackageLine = 'packageLine',
}

export type EditPriceModalCallParams = Pick<EditPriceModalProps, 'option'>;

export type EditPriceModalProps = {
  onClose(): void;
  option: Model_CommonItem;
  stepId?: keyof typeof PAGES_SETTINGS;
  categories: ScrollMenuOptions[];
};

export const EditPriceModal: FC<EditPriceModalProps> = ({
  onClose,
  option,
  stepId = defaultStepId,
  categories = [],
}) => {
  const { t } = useTranslation();
  const { updateModel } = useModelApi();
  const { sendMessage } = useStreaming();

  const { isFeatureEnabled } = useFeature();

  const { model, status, isConfigurationComplete } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);

  const isAllowEditPackageLinesDetailsFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowEditPackageLinesDetails,
  });
  const { currency } = useCurrency();

  const [mode, setMode] = useState<Mode>(Mode.Package);

  const packageLines = useMemo<ModelPackageLine[]>(() => {
    if (!isPackage(option)) return [];
    return getPackageLines({ model, packageRecord: option }).filter(
      packageLine => packageLine[ModelPackageLineFields.selected],
    );
  }, [option, model]);

  const records = useMemo<Model_CommonItem[]>(() => {
    return [option, ...packageLines];
  }, [option, packageLines]);

  const modelUpdateAttributes = getModelUpdateAttributes(
    stepId as keyof typeof PAGES_SETTINGS,
  );

  const isManuallyAdded = useMemo<boolean>(
    () => get(option, Model_CommonItemFields.manuallyAdded),
    [option],
  );

  const categoryOptions = useMemo<OptionWithKey[]>(() => {
    return mapToSelectOptions<ScrollMenuOptions>(
      categories.filter(category => category.key !== NAME_TAB_ALL),
      { label: 'name', value: 'key' },
    );
  }, [categories]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      if (!model) return;

      let updatedModel: Model = model;

      for (const item of data.rows) {
        const record = records.find(
          record => record[Model_CommonItemFields.ID] === item[Model_CommonItemFields.ID],
        ) as Model_CommonItem;

        const updatedData = pick(item, [
          Model_CommonItemFields.name,
          Model_CommonItemFields.price,
          Model_CommonItemFields.categoryCode,
          Model_CommonItemFields.purchasePrice,
        ]);
        const updateData: Model_CommonItem = {
          ...record,
          ...updatedData,
          [Model_CommonItemFields.YNchanged]: true,
        };

        if (!isPackageLine(record)) {
          updateData[Model_CommonItemFields.selected] = true;
        }

        const { response, messageHandled, status } = await updateModel(updatedModel, {
          [modelUpdateAttributes]: updateData,
        });

        if (modelToken) {
          sendMessage({
            type: StreamingEventType.EMIT_SLOT_CHANGE,
            data: {
              name: 'selection',
              data: {
                token: modelToken,
              },
            },
          });
        }

        updatedModel = response as Model;

        if (!messageHandled) {
          notification.openByStatus(status, {
            [Status.Success]: t('UPDATE_SUCCESSFUL'),
            [Status.Error]: t('GLOBAL_ERROR_TEXT'),
          });
        }
      }

      onClose();
    },
    [
      model,
      modelToken,
      modelUpdateAttributes,
      onClose,
      records,
      sendMessage,
      t,
      updateModel,
    ],
  );

  const handleModeChange = useCallback((e: RadioChangeEvent) => {
    const { value } = e.target;
    setMode(value);
  }, []);

  const canUpdate = useCallback(
    (record: PackageItem | ModelPackageLine): boolean => {
      if (!isPackage(option)) return true;
      return (
        (isPackage(record) && mode === Mode.Package) ||
        (isPackageLine(record) && mode === Mode.PackageLine)
      );
    },
    [option, mode],
  );

  const rows = useMemo<FormDataItem[]>(() => {
    return records.map(record => {
      return {
        ...pick(record, [
          Model_CommonItemFields.ID,
          Model_CommonItemFields.name,
          Model_CommonItemFields.price,
          Model_CommonItemFields.categoryCode,
          Model_CommonItemFields.purchasePrice,
        ]),
        disabled: isConfigurationComplete || !canUpdate(record),
      };
    });
  }, [records, canUpdate, isConfigurationComplete]);

  const formProps = useFormik<FormData>({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { rows },
    onSubmit: handleSubmit,
  });

  const isSubmitDisabled = useMemo<boolean>(() => {
    return status === Status.Loading || isConfigurationComplete || !formProps.dirty;
  }, [formProps.dirty, isConfigurationComplete, status]);

  return (
    <Modal variant="md" visible onCancel={onClose} center>
      <ScModalTitle>{t('CONFIG_LIST_EDIT_PRICE')}</ScModalTitle>

      <FormikProvider value={formProps}>
        <Form>
          <TableLike style={{ tableLayout: 'fixed' }}>
            <TableLike.colgroup>
              {isManuallyAdded ? (
                <>
                  <TableLike.col width={'25%'} />
                  <TableLike.col width={'25%'} />
                  <TableLike.col width={'25%'} />
                  <TableLike.col width={'25%'} />
                </>
              ) : (
                <>
                  <TableLike.col width={'50%'} />
                  <TableLike.col width={'50%'} />
                </>
              )}
            </TableLike.colgroup>
            <TableLike.tr>
              {isManuallyAdded && (
                <TableLike.td>{t('EDIT_PRICE_MODAL_CATEGORY')}</TableLike.td>
              )}
              <TableLike.td>{t('EDIT_PRICE_MODAL_DISCRIPTION')}</TableLike.td>
              {isManuallyAdded && (
                <TableLike.td>{t('EDIT_PRICE_MODAL_PURCHASE_PRICE')}</TableLike.td>
              )}
              <TableLike.td>{t('EDIT_PRICE_MODAL_SELLING_PRICE')}</TableLike.td>
            </TableLike.tr>
            <FieldArray
              name="rows"
              render={() => (
                <>
                  {rows.map((row, idx) => {
                    const namePrefix = `rows.${idx}.`;
                    return (
                      <TableLike.tr key={row[Model_CommonItemFields.ID]}>
                        {isManuallyAdded && (
                          <TableLike.td>
                            <FormField
                              component={Select}
                              options={categoryOptions}
                              name={namePrefix + Model_CommonItemFields.categoryCode}
                              fullwidth
                              showSearch
                              bordered={false}
                              size="small"
                              disabled={row.disabled}
                            />
                          </TableLike.td>
                        )}

                        {isManuallyAdded ? (
                          <TableLike.td>
                            <FormField
                              component={Input}
                              name={namePrefix + Model_CommonItemFields.name}
                              disabled={row.disabled}
                              size="small"
                            />
                          </TableLike.td>
                        ) : (
                          <TableLike.td>
                            <Typography.Text
                              style={{ maxWidth: '100%' }}
                              ellipsis
                              data-testid="edit-price-modal-option-name"
                            >
                              {row[Model_CommonItemFields.name]}
                            </Typography.Text>
                          </TableLike.td>
                        )}

                        {isManuallyAdded && (
                          <TableLike.td>
                            <FormField
                              component={InputNumberLocalized}
                              min={0}
                              step={0.01}
                              valuePrefix={currency}
                              name={namePrefix + Model_CommonItemFields.purchasePrice}
                              disabled={row.disabled}
                              size="small"
                            />
                          </TableLike.td>
                        )}

                        <TableLike.td>
                          <FormField
                            component={InputNumberLocalized}
                            min={0}
                            step={0.01}
                            valuePrefix={currency}
                            name={namePrefix + Model_CommonItemFields.price}
                            data-testid="edit-price-modal-new-selling-price-input"
                            disabled={row.disabled}
                            size="small"
                          />
                        </TableLike.td>
                      </TableLike.tr>
                    );
                  })}
                </>
              )}
            />
          </TableLike>

          {isPackage(option) && isAllowEditPackageLinesDetailsFeatureEnabled && (
            <ScCheckboxWrapper>
              <Checkbox
                value={Mode.Package}
                checked={mode === Mode.Package}
                onChange={handleModeChange}
                circle
              >
                {t('UPDATE_PACKAGE_AMOUNT')}
              </Checkbox>
              <Checkbox
                value={Mode.PackageLine}
                checked={mode === Mode.PackageLine}
                onChange={handleModeChange}
                circle
              >
                {t('UPDATE_PACKAGE_LINE_AMOUNT')}
              </Checkbox>
            </ScCheckboxWrapper>
          )}
          <ScModalFooter>
            <ScModalFooterButton
              onClick={onClose}
              disabled={status === Status.Loading}
              data-testid="edit-price-modal-cancel-btn"
            >
              {t('FORM_CANCEL')}
            </ScModalFooterButton>
            <ScModalFooterButton
              variant="primary"
              htmlType="submit"
              disabled={isSubmitDisabled}
              data-testid="edit-price-modal-submit-btn"
            >
              {t('SAVE')}
            </ScModalFooterButton>
          </ScModalFooter>
        </Form>
      </FormikProvider>
    </Modal>
  );
};
