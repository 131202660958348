import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSoftOfferApi } from 'context/softOffer/SoftOfferProvider';
import {
  SoftOfferDetails,
  SoftOfferDetailsKeys,
  SoftOfferListItemFields,
  SoftOfferUsualDriverFields,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerFields } from 'types/vendor';
import { ScTabs } from './styles';
import Layout from 'layout/Default/Layout';
import Container from 'components/container/Container';
import { useSelector } from 'react-redux';
import { configurationSelectors, softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import UsualDriverForm from './UsualDriver';
import InsurancePolicyHolderForm from './InsurancePolicyHolder';
import VehicleDetailsForm from './VehicleDetails';
import SecurityDepositForm from './SecurityDeposit';
import CheckoutForm from './Checkout';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { SOFT_OFFER_URL } from 'common/constants';
import { useRelations } from 'context/relations/RelationsProvider';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/constants';
import { SoftOfferPaymentFields } from '@hypercharge/xdms-client/lib/softOffer/types';
import OccasionalDriversForm from './OccasionalDrivers';
import AcceptionQuestionsForm from './AcceptionQuestions';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useFeature } from 'context/feature/FeatureProvider';

const TabsKeys = {
  usualDriver: SoftOfferDetailsKeys.usualDriver,
  occasionalDrivers: SoftOfferDetailsKeys.occasionalDrivers,
  acceptionQuestions: SoftOfferDetailsKeys.acceptionQuestions,
  insurancePolicyHolder: SoftOfferDetailsKeys.insurancePolicyHolder,
  vehicle: SoftOfferDetailsKeys.vehicle,
  securityDeposits: SoftOfferDetailsKeys.securityDeposit,
  payment: SoftOfferDetailsKeys.payment,
};

export const SoftOfferForm: FC = () => {
  const { t } = useTranslation();
  const { id: softOfferId } = useParams<{ id: string }>();
  const history = useHistory();

  const { relations } = useRelations();
  const {
    updateSoftOfferDetails,
    getSoftOfferDetails,
    tarificationUpdate,
    tarificationCalculate,
    tarificationPublish,
    reloadDocuments,
  } = useSoftOfferApi();

  const { isFeatureEnabled } = useFeature();
  const isSoftOfferUsualDriverFormFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferUsualDriverForm,
  });
  const isSoftOfferInsurancePolicyHolderFormFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferInsurancePolicyHolderForm,
  });
  const isSoftOfferAcceptionQuestionsFormFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferAcceptionQuestionsForm,
  });
  const isSoftOfferVehicleDetailsFormFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferVehicleDetailsForm,
  });
  const isSoftOfferOccasionalDriversFormFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferOccasionalDriversForm,
  });
  const isSoftOfferSecurityDepositFormFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferSecurityDepositForm,
  });

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const { softOfferDetails, softOfferTarificationCalculateStatus } = useSelector(
    softOffersSelectors.getAll,
  );

  useEffect(() => {
    if (!softOfferId || !configurationNumber) return;
    getSoftOfferDetails(configurationNumber, softOfferId).then(({ response }) => {
      if (!response) history.push(`${SOFT_OFFER_URL}/list`);
    });
  }, [configurationNumber, softOfferId, getSoftOfferDetails, history]);

  /** Used to:
   * prefill driver when creating softOffer;
   * have default county code for phone inputs */
  const customer = useMemo(() => relations[0]?.customer, [relations]);

  const initialValues = useMemo<SoftOfferDetails | null | undefined>(() => {
    return softOfferDetails;
  }, [softOfferDetails]);

  const defaultCountryCode = useMemo<string>(
    () => customer?.[CustomerFields.country] ?? DEFAULT_COUNTRY_CODE,
    [customer],
  );

  const isInsurancePolicyHolderEnabled = useMemo(() => {
    return !initialValues?.[SoftOfferDetailsKeys.usualDriver]?.[
      SoftOfferUsualDriverFields.driverIsHolder
    ];
  }, [initialValues]);

  const isOccasionalDriversEnabled = useMemo(() => {
    return initialValues?.[SoftOfferDetailsKeys.usualDriver]?.[
      SoftOfferUsualDriverFields.isOccasionalDriversEnabled
    ];
  }, [initialValues]);

  const isSoftOfferPublished = useMemo(
    () =>
      Boolean(
        softOfferDetails?.[SoftOfferDetailsKeys.details]?.[
          SoftOfferListItemFields.polisNumber
        ],
      ),
    [softOfferDetails],
  );

  const isPublishAvailable = useMemo(
    () => softOfferTarificationCalculateStatus === Status.Success,
    [softOfferTarificationCalculateStatus],
  );

  const documentsData = useMemo<{ url: string; name: string }[]>(() => {
    const paymentData = initialValues?.[SoftOfferDetailsKeys.payment];
    if (!paymentData) return [];

    return [
      {
        url: paymentData[SoftOfferPaymentFields.documentDealer],
        name: t('SOFT_OFFER_CHECKOUT_DOCUMENT_DEALER'),
      },
      {
        url: paymentData[SoftOfferPaymentFields.documentCustomer],
        name: t('SOFT_OFFER_CHECKOUT_DOCUMENT_CUSTOMER'),
      },
      {
        url: paymentData[SoftOfferPaymentFields.documentResignationLetter],
        name: t('SOFT_OFFER_CHECKOUT_DOCUMENT_RESIGNATION_LETTER'),
      },
    ].filter(({ url }) => url);
  }, [initialValues, t]);

  /** Has circular dep on {@link softOfferDetails} */
  const onCalculate = useCallback(async () => {
    if (!softOfferId || !configurationNumber || !softOfferDetails) return;
    await tarificationUpdate(softOfferId, softOfferDetails);
    const { status, messageHandled } = await tarificationCalculate(softOfferId);

    if (!messageHandled)
      notification.openByStatus(status, {
        [Status.Success]: t('SOFT_OFFER_TARIFICATION_CALCULATE_SUCCESS'),
        [Status.Error]: t('SOFT_OFFER_TARIFICATION_CALCULATE_ERROR'),
      });

    if (status === Status.Success)
      await getSoftOfferDetails(configurationNumber, Number(softOfferId));
  }, [
    softOfferId,
    configurationNumber,
    softOfferDetails,
    tarificationUpdate,
    tarificationCalculate,
    t,
    getSoftOfferDetails,
  ]);

  const onPublish = useCallback(async () => {
    if (!softOfferId || !configurationNumber) return;
    const { status, messageHandled } = await tarificationPublish(softOfferId);

    if (!messageHandled)
      notification.openByStatus(status, {
        [Status.Success]: t('SOFT_OFFER_TARIFICATION_PUBLISH_SUCCESS'),
        [Status.Error]: t('SOFT_OFFER_TARIFICATION_PUBLISH_ERROR'),
      });

    if (status === Status.Success)
      await getSoftOfferDetails(configurationNumber, Number(softOfferId));
  }, [configurationNumber, getSoftOfferDetails, softOfferId, t, tarificationPublish]);

  const onReloadDocuments = useCallback(async () => {
    if (!softOfferId || !configurationNumber) return;
    const { status } = await reloadDocuments(softOfferId);

    if (status === Status.Success)
      await getSoftOfferDetails(configurationNumber, Number(softOfferId));
  }, [configurationNumber, getSoftOfferDetails, reloadDocuments, softOfferId]);

  const [activeTabKey, setActiveTabKey] = useState<string>(TabsKeys.usualDriver);
  const [isActiveTabLocked, setIsActiveTabLocked] = useState<boolean>(false);

  const onTabChange = useCallback(
    (key: string) => {
      if (isActiveTabLocked && !window.confirm(t('NOT_SAVED'))) {
        return;
      }
      setIsActiveTabLocked(false);
      setActiveTabKey(key);
    },
    [isActiveTabLocked, t],
  );

  const handleSubmit = useCallback(
    async (values: Partial<SoftOfferDetails>): Promise<void> => {
      if (!configurationNumber) return;

      if (initialValues) {
        const { status, messageHandled } = await updateSoftOfferDetails(
          initialValues,
          values,
        );

        if (!messageHandled) {
          notification.openByStatus(status, {
            [Status.Success]: t('SOFT_OFFER_UPDATE_SUCCESS'),
            [Status.Error]: t('SOFT_OFFER_UPDATE_ERROR'),
          });
        }

        await getSoftOfferDetails(configurationNumber, Number(softOfferId));
      }
    },
    [
      getSoftOfferDetails,
      softOfferId,
      configurationNumber,
      t,
      initialValues,
      updateSoftOfferDetails,
    ],
  );

  return (
    <Layout withVerticalScrolling bg={false}>
      <Container size="lg">
        <ScTabs
          centered
          destroyInactiveTabPane
          activeKey={activeTabKey}
          onChange={onTabChange}
        >
          {isSoftOfferUsualDriverFormFeatureEnabled && (
            <ScTabs.TabPane tab={t('SOFT_OFFER_USUAL_DRIVER')} key={TabsKeys.usualDriver}>
              <UsualDriverForm
                isDisabled={isSoftOfferPublished}
                initialValues={initialValues?.[SoftOfferDetailsKeys.usualDriver]}
                defaultCountryCode={defaultCountryCode}
                onSubmit={values =>
                  handleSubmit({ [SoftOfferDetailsKeys.usualDriver]: values })
                }
                setIsTabLocked={setIsActiveTabLocked}
              />
            </ScTabs.TabPane>
          )}
          {isSoftOfferInsurancePolicyHolderFormFeatureEnabled && (
            <ScTabs.TabPane
              tab={t('SOFT_OFFER_INSURANCE_POLICY_HOLDER')}
              key={TabsKeys.insurancePolicyHolder}
              disabled={!isInsurancePolicyHolderEnabled}
            >
              <InsurancePolicyHolderForm
                isDisabled={!isInsurancePolicyHolderEnabled || isSoftOfferPublished}
                initialValues={
                  initialValues?.[SoftOfferDetailsKeys.insurancePolicyHolder]
                }
                defaultCountryCode={defaultCountryCode}
                onSubmit={values =>
                  handleSubmit({ [SoftOfferDetailsKeys.insurancePolicyHolder]: values })
                }
                setIsTabLocked={setIsActiveTabLocked}
              />
            </ScTabs.TabPane>
          )}
          {isSoftOfferOccasionalDriversFormFeatureEnabled && (
            <ScTabs.TabPane
              tab={t('SOFT_OFFER_OCCASIONAL_DRIVERS')}
              key={TabsKeys.occasionalDrivers}
              disabled={!isOccasionalDriversEnabled}
            >
              <OccasionalDriversForm
                isDisabled={!isOccasionalDriversEnabled || isSoftOfferPublished}
                initialValues={initialValues?.[SoftOfferDetailsKeys.occasionalDrivers]}
                defaultCountryCode={defaultCountryCode}
                onSubmit={values =>
                  handleSubmit({ [SoftOfferDetailsKeys.occasionalDrivers]: values })
                }
                setIsTabLocked={setIsActiveTabLocked}
              />
            </ScTabs.TabPane>
          )}
          {isSoftOfferAcceptionQuestionsFormFeatureEnabled && (
            <ScTabs.TabPane
              tab={t('SOFT_OFFER_ACCEPTION_QUESTIONS')}
              key={TabsKeys.acceptionQuestions}
            >
              <AcceptionQuestionsForm
                isDisabled={isSoftOfferPublished}
                initialValues={initialValues?.[SoftOfferDetailsKeys.acceptionQuestions]}
                onSubmit={values =>
                  handleSubmit({ [SoftOfferDetailsKeys.acceptionQuestions]: values })
                }
              />
            </ScTabs.TabPane>
          )}
          {isSoftOfferVehicleDetailsFormFeatureEnabled && (
            <ScTabs.TabPane tab={t('SOFT_OFFER_VEHICLE_DETAILS')} key={TabsKeys.vehicle}>
              <VehicleDetailsForm
                isDisabled={isSoftOfferPublished}
                initialValues={initialValues?.[SoftOfferDetailsKeys.vehicle]}
                onSubmit={values =>
                  handleSubmit({ [SoftOfferDetailsKeys.vehicle]: values })
                }
                setIsTabLocked={setIsActiveTabLocked}
              />
            </ScTabs.TabPane>
          )}
          {isSoftOfferSecurityDepositFormFeatureEnabled && (
            <ScTabs.TabPane
              tab={t('SOFT_OFFER_SECURITY_DEPOSITS')}
              key={TabsKeys.securityDeposits}
            >
              <SecurityDepositForm
                isDisabled={isSoftOfferPublished}
                initialValues={initialValues?.[SoftOfferDetailsKeys.securityDeposit]}
                initialValuesPayment={initialValues?.[SoftOfferDetailsKeys.payment]}
                onSubmit={(securityDepositValues, paymentValues) =>
                  handleSubmit({
                    [SoftOfferDetailsKeys.securityDeposit]: securityDepositValues,
                    [SoftOfferDetailsKeys.payment]: paymentValues,
                  })
                }
                setIsTabLocked={setIsActiveTabLocked}
              />
            </ScTabs.TabPane>
          )}
          <ScTabs.TabPane tab={t('SOFT_OFFER_CHECKOUT')} key={TabsKeys.payment}>
            <CheckoutForm
              isDisabled={isSoftOfferPublished}
              isPublishDisabled={!isPublishAvailable || isSoftOfferPublished}
              isDocumentsVisible={isSoftOfferPublished}
              tableData={initialValues?.[SoftOfferDetailsKeys.checkout]}
              documentsData={documentsData}
              onCalculate={onCalculate}
              onPublish={onPublish}
              onReloadDocuments={onReloadDocuments}
            />
          </ScTabs.TabPane>
        </ScTabs>
      </Container>
    </Layout>
  );
};
