import { useXdmsClient } from '../xdms/XdmsClient';
import { useCallback, useState } from 'react';
import { RequestResponseDetails } from '../../types/common';
import { Status } from '../../utils/types';
import { notification } from '../../utils/notification';
import {
  StockMachineFields,
  Machine,
  Filter,
  StockMachine,
  StockMachineBrandFields,
  StockMachineColorFields,
  StockMachineFuelFields,
  StockMachineInteriorFields,
  StockMachineLocationFields,
  StockMachineModelFields,
  StockMachineFilterBrandItem,
  StockMachineFilterColorItem,
  StockMachineFilterFuelItem,
  StockMachineFilterInteriorItem,
  StockMachineFilterLocationItem,
  StockMachineFilterModelItem,
  StockMachineOption,
} from 'types/vendor';
import * as Sentry from '@sentry/react';
import { OptionWithKey } from 'types/common';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';

interface HookValue {
  status: Status;
  getMachinesList(
    filter: Filter<keyof Machine>,
  ): Promise<RequestResponseDetails<Machine[]>>;
  getStockMachinesList(
    filter?: Filter<StockMachineFields>,
  ): Promise<RequestResponseDetails<StockMachine[]>>;
  getStockMachineOptionsList(
    machineCode: string,
  ): Promise<RequestResponseDetails<StockMachineOption[]>>;
  getModelOptions(filters?: Filter<StockMachineModelFields>): Promise<OptionWithKey[]>;
  getLocationOptions(
    filters?: Filter<StockMachineLocationFields>,
  ): Promise<OptionWithKey[]>;
  getInteriorOptions(
    filters?: Filter<StockMachineInteriorFields>,
  ): Promise<OptionWithKey[]>;
  getFuelOptions(filters?: Filter<StockMachineFuelFields>): Promise<OptionWithKey[]>;
  getColorOptions(filters?: Filter<StockMachineColorFields>): Promise<OptionWithKey[]>;
  getBrandOptions(filters?: Filter<StockMachineBrandFields>): Promise<OptionWithKey[]>;
}

export const useMachineApi = (): HookValue => {
  const { xdmsClientTyped: xdmsClient } = useXdmsClient();

  const [status, setStatus] = useState(Status.Idle);

  const getMachinesList = useCallback<HookValue['getMachinesList']>(
    async filter => {
      let result: RequestResponseDetails<Machine[]> = { status: Status.Idle };

      try {
        setStatus(Status.Loading);
        const records = await xdmsClient.machine.getList(filter);

        setStatus(Status.Success);

        result = { status: Status.Success, response: records };
      } catch (e) {
        notification.requestError(e, setStatus);

        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [xdmsClient],
  );

  const getStockMachinesList = useCallback<HookValue['getStockMachinesList']>(
    async (filter = []) => {
      let result: RequestResponseDetails<StockMachine[]> = { status: Status.Idle };

      try {
        setStatus(Status.Loading);
        const records = await xdmsClient.machine.getListFromStock(filter);

        setStatus(Status.Success);

        result = { status: Status.Success, response: records };
      } catch (e) {
        notification.requestError(e, setStatus);

        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [xdmsClient],
  );

  const getStockMachineOptionsList = useCallback<HookValue['getStockMachineOptionsList']>(
    async machineNumber => {
      let result: RequestResponseDetails<StockMachineOption[]> = { status: Status.Idle };

      try {
        setStatus(Status.Loading);
        const records = await xdmsClient.machine.getStockMachineOptionsList(
          machineNumber,
        );

        setStatus(Status.Success);

        result = { status: Status.Success, response: records };
      } catch (e) {
        notification.requestError(e, setStatus);

        result = { status: Status.Error, messageHandled: true };
      }

      return result;
    },
    [xdmsClient],
  );

  const getModelOptions = useCallback<HookValue['getModelOptions']>(
    async filters => {
      let rawOptions: StockMachineFilterModelItem[] = [];
      try {
        rawOptions = await xdmsClient.machine.getListFromStockFilterModel(filters);
      } catch (error) {
        Sentry.captureException(error, { extra: { filterKey: 'model' } });
        notification.requestError(error);
      }

      return mapToSelectOptions(rawOptions, {
        label: [StockMachineModelFields.modelNumber, StockMachineModelFields.modelName],
        value: StockMachineModelFields.modelNumber,
        separator: ' - ',
      });
    },
    [xdmsClient.machine],
  );

  const getLocationOptions = useCallback<HookValue['getLocationOptions']>(
    async filters => {
      let rawOptions: StockMachineFilterLocationItem[] = [];
      try {
        rawOptions = await xdmsClient.machine.getListFromStockFilterWarehouse(filters);
      } catch (error) {
        Sentry.captureException(error, { extra: { filterKey: 'location' } });
        notification.requestError(error);
      }

      return mapToSelectOptions(rawOptions, {
        label: [StockMachineLocationFields.code, StockMachineLocationFields.name],
        value: StockMachineLocationFields.code,
        separator: ' - ',
      });
    },
    [xdmsClient.machine],
  );

  const getFuelOptions = useCallback<HookValue['getFuelOptions']>(
    async filters => {
      let rawOptions: StockMachineFilterFuelItem[] = [];
      try {
        rawOptions = await xdmsClient.machine.getListFromStockFilterFuel(filters);
      } catch (error) {
        Sentry.captureException(error, { extra: { filterKey: 'fuel' } });
        notification.requestError(error);
      }

      return mapToSelectOptions(rawOptions, {
        label: [StockMachineFuelFields.code, StockMachineFuelFields.name],
        value: StockMachineFuelFields.code,
        separator: ' - ',
      });
    },
    [xdmsClient.machine],
  );

  const getInteriorOptions = useCallback<HookValue['getInteriorOptions']>(
    async filters => {
      let rawOptions: StockMachineFilterInteriorItem[] = [];
      try {
        rawOptions = await xdmsClient.machine.getListFromStockFilterInterior(filters);
      } catch (error) {
        Sentry.captureException(error, { extra: { filterKey: 'interior' } });
        notification.requestError(error);
      }

      return mapToSelectOptions(rawOptions, {
        label: StockMachineInteriorFields.name,
        value: StockMachineInteriorFields.code,
        separator: ' - ',
      });
    },
    [xdmsClient.machine],
  );

  const getColorOptions = useCallback<HookValue['getColorOptions']>(
    async filters => {
      let rawOptions: StockMachineFilterColorItem[] = [];
      try {
        rawOptions = await xdmsClient.machine.getListFromStockFilterColor(filters);
      } catch (error) {
        Sentry.captureException(error, { extra: { filterKey: 'color' } });
        notification.requestError(error);
      }

      return mapToSelectOptions(rawOptions, {
        label: [StockMachineColorFields.code, StockMachineColorFields.name],
        value: StockMachineColorFields.code,
        separator: ' - ',
      });
    },
    [xdmsClient.machine],
  );

  const getBrandOptions = useCallback<HookValue['getBrandOptions']>(
    async filters => {
      let rawOptions: StockMachineFilterBrandItem[] = [];
      try {
        rawOptions = await xdmsClient.machine.getListFromStockFilterBrand(filters);
      } catch (error) {
        Sentry.captureException(error, { extra: { filterKey: 'brand' } });
        notification.requestError(error);
      }

      return mapToSelectOptions(rawOptions, {
        label: StockMachineBrandFields.name,
        value: StockMachineBrandFields.name,
      });
    },
    [xdmsClient.machine],
  );

  return {
    status,
    getMachinesList,
    getStockMachinesList,
    getStockMachineOptionsList,
    getModelOptions,
    getLocationOptions,
    getInteriorOptions,
    getFuelOptions,
    getColorOptions,
    getBrandOptions,
  };
};
