import { FeatureFlagColumnSettings } from 'types/common';

export type DynamicStepFeaturesFlags = {
  YNallowExtendedViewByDefault: boolean;
  YNAllowExtendedView: boolean;
  YNallowAddComment: boolean;
  YNallowAddAsOptional: boolean;
  YNallowManageDiscount: boolean;
  YNallowManageDetails: boolean;
  YNallowManageDetailsOnlySelfMade: boolean;
  YNallowManageDocuments: boolean;
  YNallowViewDetails: boolean;
  YNallowAddNew: boolean;
  YNallowQuantitySelection: boolean;
  YNallowBackgroundImage: boolean;
  YNallowStandardFeatures: boolean;
  YNallowStandardOptionAmountAlwaysChange: boolean;
  YNallowDisableForStockConfiguration: boolean;
  YNallowSilentSortByGrossPriceDescending: boolean;
  YNallowMakeOptionalModal: boolean;

  columnsList: FeatureFlagColumnSettings[];

  [k: string]: unknown;
};

export type DynamicStepFeaturesFlagsGrouped = Record<
  string,
  DynamicStepFeaturesFlags
> | null;

export const defaultDynamicPagesFeaturesFlags: DynamicStepFeaturesFlagsGrouped = {
  PKG: {
    YNallowExtendedViewByDefault: false,
    YNAllowExtendedView: false,
    YNallowAddComment: true,
    YNallowAddAsOptional: true,
    YNallowManageDiscount: true,
    YNallowManageDetails: true,
    YNallowManageDetailsOnlySelfMade: false,
    YNallowManageDocuments: true,
    YNallowViewDetails: false,
    YNallowAddNew: true,
    YNallowQuantitySelection: true,
    YNallowBackgroundImage: false,
    YNallowStandardFeatures: false,
    YNallowStandardOptionAmountAlwaysChange: false,
    YNallowDisableForStockConfiguration: true,
    YNallowSilentSortByGrossPriceDescending: false,
    YNallowMakeOptionalModal: false,
    columnsList: [
      { name: 'Selection' },
      { name: 'CDcat' },
      { name: 'INopt' },
      { name: 'Comments' },
      { name: 'Attachments' },
      { name: 'Photo' },
      { name: 'CDopt' },
      { name: 'CNopt' },
      { name: 'PRoutGross', sort: 'ascend' },
      { name: 'PCdisc' },
      { name: 'PRoutNetTotal' },
    ],
  },
  OPT: {
    YNallowExtendedViewByDefault: false,
    YNAllowExtendedView: false,
    YNallowAddComment: true,
    YNallowAddAsOptional: true,
    YNallowManageDiscount: true,
    YNallowManageDetails: true,
    YNallowManageDetailsOnlySelfMade: false,
    YNallowManageDocuments: true,
    YNallowViewDetails: false,
    YNallowAddNew: true,
    YNallowQuantitySelection: true,
    YNallowBackgroundImage: false,
    YNallowStandardFeatures: false,
    YNallowStandardOptionAmountAlwaysChange: false,
    YNallowDisableForStockConfiguration: true,
    YNallowSilentSortByGrossPriceDescending: false,
    YNallowMakeOptionalModal: false,
    columnsList: [
      { name: 'Selection' },
      { name: 'CDcat' },
      { name: 'INopt' },
      { name: 'Comments' },
      { name: 'Attachments' },
      { name: 'Photo' },
      { name: 'CDopt' },
      { name: 'CNopt' },
      { name: 'PRoutGross' },
      { name: 'PCdisc' },
      { name: 'PRoutNetTotal' },
    ],
  },
  ACC: {
    YNallowExtendedViewByDefault: false,
    YNAllowExtendedView: false,
    YNallowAddComment: true,
    YNallowAddAsOptional: true,
    YNallowManageDiscount: true,
    YNallowManageDetails: true,
    YNallowManageDetailsOnlySelfMade: false,
    YNallowManageDocuments: true,
    YNallowViewDetails: false,
    YNallowAddNew: true,
    YNallowQuantitySelection: true,
    YNallowBackgroundImage: false,
    YNallowStandardFeatures: false,
    YNallowStandardOptionAmountAlwaysChange: false,
    YNallowDisableForStockConfiguration: false,
    YNallowSilentSortByGrossPriceDescending: false,
    YNallowMakeOptionalModal: false,
    columnsList: [
      { name: 'Selection' },
      { name: 'CDcat' },
      { name: 'INopt' },
      { name: 'Comments' },
      { name: 'Attachments' },
      { name: 'Photo' },
      { name: 'CDopt' },
      { name: 'CNopt' },
      { name: 'PRoutGross' },
      { name: 'PCdisc' },
      { name: 'PRoutNetTotal' },
    ],
  },
  'T&C': {
    YNallowExtendedViewByDefault: false,
    YNAllowExtendedView: false,
    YNallowAddComment: true,
    YNallowAddAsOptional: true,
    YNallowManageDiscount: true,
    YNallowManageDetails: true,
    YNallowManageDetailsOnlySelfMade: false,
    YNallowManageDocuments: true,
    YNallowViewDetails: false,
    YNallowAddNew: true,
    YNallowQuantitySelection: true,
    YNallowBackgroundImage: false,
    YNallowStandardFeatures: false,
    YNallowStandardOptionAmountAlwaysChange: false,
    YNallowDisableForStockConfiguration: false,
    YNallowSilentSortByGrossPriceDescending: false,
    YNallowMakeOptionalModal: false,
    columnsList: [
      { name: 'Selection' },
      { name: 'CDcat' },
      { name: 'INopt' },
      { name: 'Comments' },
      { name: 'Attachments' },
      { name: 'Photo' },
      { name: 'CDopt' },
      { name: 'CNopt' },
      { name: 'PRoutGross' },
      { name: 'PCdisc' },
      { name: 'PRoutNetTotal' },
    ],
  },
};
