import * as Yup from 'yup';
import {
  SoftOfferInsurancePolicyHolder,
  SoftOfferInsurancePolicyHolderFields,
} from 'types/vendor';
import { TFunction } from 'i18next';
import { SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY } from 'common/constants';

export const getSchema = (t: TFunction): Yup.ObjectSchema => {
  return Yup.object().shape<Partial<SoftOfferInsurancePolicyHolder>>({
    [SoftOfferInsurancePolicyHolderFields.lastName]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v !== SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.firstName]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v !== SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.birthdayAt]: Yup.string()
      .nullable()
      .when(SoftOfferInsurancePolicyHolderFields.personType, {
        is: v => v !== SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().nullable().required(t('FORM__REQUIRED')),
      }),
    [SoftOfferInsurancePolicyHolderFields.sex]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v !== SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    // [SoftOfferInsurancePolicyHolderFields.licenceCategoryCode]: Yup.string().required(
    //   t('FORM__REQUIRED'),
    // ),
    // [SoftOfferInsurancePolicyHolderFields.licence]: Yup.string()
    //   .nullable()
    //   .required(t('FORM__REQUIRED')),
    [SoftOfferInsurancePolicyHolderFields.number]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v === SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.box]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v === SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.personType]: Yup.string().required(
      t('FORM__REQUIRED'),
    ),
    [SoftOfferInsurancePolicyHolderFields.enterpriseName]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.enterpriseNumber]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.enterpriseLegalForm]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.street]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v === SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.locality]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v === SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.zipCode]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v === SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.phoneGsm]: Yup.string(),
    [SoftOfferInsurancePolicyHolderFields.phone]: Yup.string(),
    [SoftOfferInsurancePolicyHolderFields.language]: Yup.string(),
    [SoftOfferInsurancePolicyHolderFields.email]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v === SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
    [SoftOfferInsurancePolicyHolderFields.job]: Yup.string().when(
      SoftOfferInsurancePolicyHolderFields.personType,
      {
        is: v => v !== SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY,
        then: Yup.string().required(t('FORM__REQUIRED')),
      },
    ),
  });
};
