import {
  MessageCodeValues,
  MessageFields,
  Model,
  ModelFields,
  ModelItemFields,
  ModelUpdateAttributesObjectFields,
  Model_CommonCategoryFields,
  Model_CommonItem,
  Model_CommonItemFields,
  PackageItem,
  WebImageItemFields,
} from '@hypercharge/xdms-client/lib/types';
import classnames from 'classnames';
import {
  DEFAULT_SFV,
  EXTENDED_DYNAMIC_TABLE_VIEW,
  IMAGE_GALLERY_THUMBNAIL_HEIGHT,
  IMAGE_GALLERY_THUMBNAIL_WIDTH,
  MODELS_URL,
  URL_QUERY_PARAMS,
} from 'common/constants';
import { DynamicStepFeaturesFlags } from 'common/dynamicPagesFeaturesFlags';
import { sizes } from 'common/theme';
import Container from 'components/container/Container';
import DynamicPageModals from './Modals';
import { CommentsModalCallParams, CommentsModalProps } from './Modals/CommentsModal';
import { CustomOptionModalProps } from './Modals/CreateOptionModal';
import { DiscountModalCallParams, DiscountModalProps } from './Modals/DiscountModal';
import { DocumentModalProps } from './Modals/DocumentModal';
import {
  EditPriceModalCallParams,
  EditPriceModalProps,
} from './Modals/EditPriceModal/EditPriceModal';
import {
  PackageLinesModalCallParams,
  PackageLinesModalProps,
} from './Modals/PackageLinesModal';
import { RulesModalCallParams, RulesModalProps } from './Modals/RulesModal/RulesModal';
import {
  MakeOptionalModalCallParams,
  MakeOptionalModalProps,
} from './Modals/MakeOptionalModal';
import { useExternalStorage } from 'context/externalStorage/ExternalStorageProvider';
import { FeatureSource, useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import { useQuery } from 'context/router/UrlQueryProvider';
import { useStep } from 'context/step/StepProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import {
  CategoryModelItem,
  PAGES_SETTINGS,
  useTableCategories,
} from 'context/tableCategories/TableCategoriesProvider';
import { useBackgroundImage } from 'hooks/useBackgroundImage';
import Layout from 'layout/Default/Layout';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { ImageItem } from 'types/common';
import { get } from 'utils';
import {
  CommentsModalTabs,
  DEFAULT_DOCUMENT_NAME_KEY,
  DEFAULT_TABLE_PAGE_SIZE,
  NAME_TAB_ALL,
  NO_IMAGE_PATH,
} from 'utils/constants';
import { sortTableDataBySearchValue } from 'utils/format';
import { notification, NotificationType } from 'utils/notification';
import { Status } from 'utils/types';
import { useModalState } from '../../hooks/useModalState';
import { featuresFlagsSelectors, modelSelectors, sharedSelectors } from '../../store';
import { columnKey } from './constants';
import { ScTable } from './index.styles';
import { OptionWithError } from './types';
import { useColumns } from './use-columns';
import { getFilteredTableData, isPackage } from './utils';
import { getRecordRules } from './utils/get-record-rules';
import { isCommonItemDoesntFitAnyRule } from './utils/isCommonItemDoesntFitAnyRule';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import TableImageGallery from 'components/ImageGalleryModal';
import SplitPane from 'components/table/splitPane';
import { ModelImage } from 'components/ModelImage';
import TableFilters from 'components/table/filters';
import TableBreadcrumbs from 'components/table/breadcrumbs';
import TableSubcategory from 'components/table/subcategory';
import { StreamingEventType } from 'context/streaming/types';
import { isPackageHasSelection } from './utils/isPackageHasSelection';
import { getResetKey } from 'utils/get-reset-key';
import { Button } from 'components/button';
import { createPortal } from 'react-dom';
import { isStandardOption } from './utils/is-standard-option';

interface DynamicPageProps {
  stepId: keyof typeof PAGES_SETTINGS;
}

export const DynamicPage: FC<DynamicPageProps> = ({ stepId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { query, setQueryParams, queryValues, removeQueryParams, initialQuery } =
    useQuery();
  const { getModel, updateModel } = useModelApi();
  const { handlePrevStep, handleNextStep } = useStep();
  const { pathname } = useLocation();
  const {
    handleChangeRootCategory,
    scrollMenuOptions,
    allScrollMenuOptions,
    subcategories,
    handleSelectSubcategory,
    breadcrumbs,
    setCreateVirtualCategory,
    selectedScrollMenuOption,
  } = useTableCategories();
  const { isFeatureEnabled } = useFeature();
  const { getOptimizedImageUrl } = useExternalStorage();
  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);

  const { getDocumentsList } = useDocumentsApi();

  const {
    model,
    isConfigurationComplete,
    modelRules,
    status: modelStatus,
  } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);
  const currentPageFeatures = useSelector(featuresFlagsSelectors.getCurrentPageFeatures);

  const { sendMessage } = useStreaming();

  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });
  const isSmAndWider = useMediaQuery({ minWidth: sizes.sm });

  const isAddDynamicStepItemFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowAddNew',
    source: FeatureSource.DYNAMIC,
  });
  const isAllowExtendedViewFeatureEnabled = isFeatureEnabled({
    feature: 'YNAllowExtendedView',
    source: FeatureSource.DYNAMIC,
  });
  const isStandardFeaturesFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowStandardFeatures',
    source: FeatureSource.DYNAMIC,
  });
  // the same feature is used in columns hook for this page
  // make it possible to define the feature flag once
  // either in page itself (here) or in any other place
  const isAllowViewDetailsFeatureEnabled = isFeatureEnabled({
    feature: 'YNallowViewDetails',
    source: FeatureSource.DYNAMIC,
  });
  const isAllowSilentSortByGrossPriceAscending = isFeatureEnabled({
    feature: 'YNallowSilentSortByGrossPriceDescending',
    source: FeatureSource.DYNAMIC,
  });
  const isAllowMakeOptionalModal = isFeatureEnabled({
    feature: 'YNallowMakeOptionalModal',
    source: FeatureSource.DYNAMIC,
  });

  const {
    MODEL_TABLE_ITEMS_FIELDS,
    SELECTED_CATEGORY_NAME,
    MODEL_TABLE,
    MODEL_UPDATE_ATTRIBUTES,
    SELECTED_SUBCATEGORIES,
    DOCUMENT_ENTITY_RELATION_CODE,
    STREAMING_OPTION_TYPE,
  } = PAGES_SETTINGS[stepId];

  const [tableData, setTableData] = useState<Model_CommonItem[]>([]);
  const [carouselImages, setCarouselImages] = useState<ImageItem[]>([]);
  const [optionsWithError, addOptionWithError] = useState<OptionWithError[]>([]);
  const [latestOption, setLatestOption] = useState<Model_CommonItem | undefined>();
  const [isTableViewExtended, setIsTableViewExtended] = useState<boolean>(
    get(initialQuery, EXTENDED_DYNAMIC_TABLE_VIEW) === 'true',
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [stickyOffset, setStickyOffset] = useState<number>(0);

  useMemo(() => {
    const value = currentPageFeatures?.['YNallowExtendedViewByDefault'] as
      | boolean
      | undefined;
    setIsTableViewExtended(Boolean(value));
  }, [currentPageFeatures]);

  useEffect(() => {
    setSearchValue('');
  }, [pathname]);

  useEffect(() => {
    const queryValue = get(query, EXTENDED_DYNAMIC_TABLE_VIEW) === 'true';
    if (queryValue !== isTableViewExtended) {
      setQueryParams({
        key: EXTENDED_DYNAMIC_TABLE_VIEW,
        value: String(isTableViewExtended),
      });
    }
  }, [isTableViewExtended, query, setQueryParams]);

  const { getBackgroundImage, backgroundImage, canShowBackgroundImage, isFallback } =
    useBackgroundImage({
      isExtendedViewByDefault: {
        feature: 'YNallowExtendedViewByDefault',
        source: FeatureSource.DYNAMIC,
      },
    });

  const hasPhotoColumn = useMemo<boolean>(() => {
    const columnsSettings = currentPageFeatures?.columnsList as
      | DynamicStepFeaturesFlags['columnsList']
      | undefined;

    if (!columnsSettings) return true;

    const photoColumn = columnsSettings.find(({ name }) => name === columnKey.PHOTO);

    return Boolean(photoColumn);
  }, [currentPageFeatures]);

  const isAddItemButtonVisible = useMemo<boolean>(
    (): boolean =>
      isAddDynamicStepItemFeatureEnabled &&
      (allScrollMenuOptions.some(category => category.key !== NAME_TAB_ALL) ||
        !allScrollMenuOptions.length),
    [isAddDynamicStepItemFeatureEnabled, allScrollMenuOptions],
  );

  // these keys used in search to filter by
  const filteringKeys = useMemo(() => {
    const {
      name,
      ID,
      defaultAmount,
      purchasePrice,
      price,
      discountPercent,
      nettoSellingPrice,
      totalSellingPrice,
      categoryId,
    } = Model_CommonItemFields;
    return [
      name,
      ID,
      defaultAmount,
      purchasePrice,
      price,
      discountPercent,
      nettoSellingPrice,
      totalSellingPrice,
      categoryId,
    ];
  }, []);

  const onRow = useCallback(
    (row: Model_CommonItem) => {
      return {
        className: classnames('option__row', {
          completed: isConfigurationComplete,
          latest:
            latestOption &&
            latestOption[Model_CommonItemFields.ID] === row[Model_CommonItemFields.ID],
        }),
      };
    },
    [isConfigurationComplete, latestOption],
  );

  const handleStandardFeaturesValueChange = useCallback(
    (isStandardFeaturesEnabled: boolean): void => {
      const packageLines =
        MODEL_TABLE === ModelFields.packages
          ? model?.[ModelFields.packageLines]
          : undefined;

      if (isStandardFeaturesEnabled) {
        const { tableData } = getFilteredTableData({
          items: get(model, MODEL_TABLE, []) as Model_CommonItem[],
          stepId,
          selectedCategory: get(query, SELECTED_CATEGORY_NAME, ''),
          selectedSubCategory: get(query, SELECTED_SUBCATEGORIES, ''),
          isSortingByGrossPriceAscendingEnabled: isAllowSilentSortByGrossPriceAscending,
          isPricesWithVATenabled: shouldShowPricesWithVAT,
        });
        setTableData(
          sortTableDataBySearchValue({
            data: tableData,
            searchValue,
            filteringKeys,
            packageLines,
          }),
        );
      } else {
        setTableData(prevState => {
          const newData = prevState.filter(option => !isStandardOption(option));
          return sortTableDataBySearchValue({
            data: newData,
            searchValue,
            filteringKeys,
            packageLines,
          });
        });
      }

      setQueryParams({
        key: URL_QUERY_PARAMS.standardFeaturesValue,
        value: String(isStandardFeaturesEnabled),
      });
    },
    [
      MODEL_TABLE,
      SELECTED_CATEGORY_NAME,
      SELECTED_SUBCATEGORIES,
      filteringKeys,
      isAllowSilentSortByGrossPriceAscending,
      model,
      query,
      searchValue,
      setQueryParams,
      shouldShowPricesWithVAT,
      stepId,
    ],
  );

  const getImageUrl = useCallback(
    (image: string | undefined) => {
      let result: string | undefined;
      try {
        result = getOptimizedImageUrl({
          fileName: image,
          options: {
            resize: {
              width: IMAGE_GALLERY_THUMBNAIL_WIDTH,
              height: IMAGE_GALLERY_THUMBNAIL_HEIGHT,
            },
          },
        });
      } catch (e) {
        notification.open({ message: String(e), type: NotificationType.error });
      }

      return result ?? NO_IMAGE_PATH;
    },
    [getOptimizedImageUrl],
  );

  const handleViewImages = useCallback(
    async (record?: Model_CommonItem): Promise<void> => {
      const hasOptionImages = record?.[Model_CommonItemFields.hasPhotos];
      if (!get(record, MODEL_TABLE_ITEMS_FIELDS.imageLink, false) && !hasOptionImages) {
        setCarouselImages([]);
        return;
      }
      const optionUrlId = get(record, MODEL_TABLE_ITEMS_FIELDS.imageLink);
      const webImages = get(model, ModelFields.webImage, []);
      const urlObjects = webImages.filter(
        item => get(item, WebImageItemFields.sysLink) === optionUrlId,
      );

      const carouselImages: ImageItem[] = [];

      const recordImageLink = get(record, MODEL_TABLE_ITEMS_FIELDS.imageLink);
      if (urlObjects.length > 0 && recordImageLink) {
        urlObjects.forEach(img => {
          carouselImages.push({
            id: recordImageLink,
            image: getImageUrl(get(img, WebImageItemFields.url)),
            alt: get(record, Model_CommonItemFields.name, ''),
          });
        });
      }

      if (hasOptionImages && record) {
        const { response: documents } = await getDocumentsList(
          DOCUMENT_ENTITY_RELATION_CODE,
          record,
        );
        if (documents && Array.isArray(documents)) {
          documents
            .filter(document => document.isPhoto)
            .forEach(document => {
              carouselImages.push({
                id: String(document.id),
                image: getImageUrl(document.fileUrl),
                alt: document.name[DEFAULT_DOCUMENT_NAME_KEY],
              });
            });
        }
      }

      setCarouselImages(carouselImages);
    },
    [
      MODEL_TABLE_ITEMS_FIELDS.imageLink,
      model,
      getImageUrl,
      getDocumentsList,
      DOCUMENT_ENTITY_RELATION_CODE,
    ],
  );

  const getErrorInOption = useCallback(
    (value: Model_CommonItem): string => {
      const optionWithError = optionsWithError.find(
        record => record.id === get(value, MODEL_TABLE_ITEMS_FIELDS.ID, ''),
      );
      return get(optionWithError, 'description', '');
    },
    [optionsWithError, MODEL_TABLE_ITEMS_FIELDS.ID],
  );

  const onSearch = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>): void => {
      const { tableData } = getFilteredTableData({
        items: get(model, MODEL_TABLE, []) as Model_CommonItem[],
        stepId,
        selectedCategory: get(query, SELECTED_CATEGORY_NAME, ''),
        selectedSubCategory: get(query, SELECTED_SUBCATEGORIES, ''),
        isSortingByGrossPriceAscendingEnabled: isAllowSilentSortByGrossPriceAscending,
        isPricesWithVATenabled: shouldShowPricesWithVAT,
      });
      setSearchValue(target.value);

      const packageLines =
        MODEL_TABLE === ModelFields.packages
          ? model?.[ModelFields.packageLines]
          : undefined;
      setTableData(
        sortTableDataBySearchValue({
          data: tableData,
          searchValue: target.value,
          filteringKeys,
          packageLines: packageLines,
        }),
      );
    },
    [
      MODEL_TABLE,
      SELECTED_CATEGORY_NAME,
      SELECTED_SUBCATEGORIES,
      filteringKeys,
      isAllowSilentSortByGrossPriceAscending,
      model,
      query,
      shouldShowPricesWithVAT,
      stepId,
    ],
  );

  const setTableParams = useCallback(
    (items: Model_CommonItem[] = []): Model_CommonItem[] => {
      const { tableData } = getFilteredTableData({
        items,
        stepId,
        selectedCategory: get(query, SELECTED_CATEGORY_NAME, ''),
        selectedSubCategory: get(query, SELECTED_SUBCATEGORIES, ''),
        isSortingByGrossPriceAscendingEnabled: isAllowSilentSortByGrossPriceAscending,
        isPricesWithVATenabled: shouldShowPricesWithVAT,
      });
      setCreateVirtualCategory(!!tableData.length);

      const packageLines =
        MODEL_TABLE === ModelFields.packages
          ? model?.[ModelFields.packageLines]
          : undefined;
      setTableData(
        sortTableDataBySearchValue({
          data: tableData,
          searchValue,
          filteringKeys,
          packageLines,
        }),
      );

      return tableData;
    },
    [
      MODEL_TABLE,
      model,
      stepId,
      query,
      SELECTED_CATEGORY_NAME,
      SELECTED_SUBCATEGORIES,
      isAllowSilentSortByGrossPriceAscending,
      shouldShowPricesWithVAT,
      setCreateVirtualCategory,
      searchValue,
      filteringKeys,
    ],
  );

  // + MODALS
  const [commentsModalState, handleCommentsModal] = useModalState<
    CommentsModalCallParams,
    Omit<
      CommentsModalProps,
      'onClose' | keyof Omit<CommentsModalCallParams, 'defaultActiveKey'>
    >,
    CommentsModalProps
  >({ stepId: stepId, defaultActiveKey: CommentsModalTabs.internal });

  const [editPriceModalState, handleEditPriceModal] = useModalState<
    EditPriceModalCallParams,
    Omit<EditPriceModalProps, 'onClose' | keyof EditPriceModalCallParams>,
    EditPriceModalProps
  >({ stepId: stepId, categories: scrollMenuOptions });

  const [discountModalState, handleDiscountModal] = useModalState<
    DiscountModalCallParams,
    Omit<DiscountModalProps, 'onClose' | keyof DiscountModalCallParams>,
    DiscountModalProps
  >({ stepId: stepId });

  const [documentsModalState, handleDocumentsModal] = useModalState<
    { option: Model_CommonItem },
    Record<string, never>,
    DocumentModalProps
  >({}, callParams => ({
    relationEntityCode: DOCUMENT_ENTITY_RELATION_CODE,
    relatedEntity: callParams.option,
    isAddFileVisible: true,
    isAddLinkVisible: true,
    onClose: () => getModel(),
  }));

  const [packageLinesModalState, handlePackageLinesModal] = useModalState<
    PackageLinesModalCallParams,
    Omit<PackageLinesModalProps, 'onClose' | keyof PackageLinesModalCallParams>,
    PackageLinesModalProps
  >({});

  const [createOptionModalState, handleCreateOptionModal] = useModalState<
    void,
    Omit<CustomOptionModalProps, 'onClose'>,
    CustomOptionModalProps
  >({ stepId: stepId, categories: allScrollMenuOptions });

  const [rulesModalState, handleRulesModal] = useModalState<
    RulesModalCallParams,
    Omit<RulesModalProps, 'onClose' | keyof RulesModalCallParams>,
    RulesModalProps
  >({});

  const [makeOptionalModalState, handleMakeOptionalModal] = useModalState<
    MakeOptionalModalCallParams,
    Omit<MakeOptionalModalProps, 'onClose' | keyof MakeOptionalModalCallParams>,
    MakeOptionalModalProps
  >({ stepId: stepId });
  // - MODALS

  const _handleDeselectRecord = useCallback(
    async (record: Model_CommonItem): Promise<Model | null> => {
      if (!model) return null;
      const { status, response, messageHandled } = await updateModel(model, {
        [MODEL_UPDATE_ATTRIBUTES]: {
          ...record,
          [MODEL_TABLE_ITEMS_FIELDS.selected]: false,
        },
      });

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TABLE_UPDATE_CONFIGURATION_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }

      return response ?? null;
    },
    [updateModel, model, MODEL_UPDATE_ATTRIBUTES, MODEL_TABLE_ITEMS_FIELDS.selected, t],
  );

  const handleSelectFlatRecord = useCallback(
    async (record: Model_CommonItem): Promise<Model | null> => {
      if (!model) return null;

      const recordRules = getRecordRules(modelRules, record[Model_CommonItemFields.ID]);

      const isModalNeeded = isCommonItemDoesntFitAnyRule(model, modelRules, recordRules);

      if (isModalNeeded) {
        handleRulesModal({
          option: record,
          rules: recordRules,
        });
        return null;
      }

      const { status, messageHandled, response } = await updateModel(model, {
        [MODEL_UPDATE_ATTRIBUTES]: {
          ...record,
          [MODEL_TABLE_ITEMS_FIELDS.selected]: true,
        },
      });

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TABLE_UPDATE_CONFIGURATION_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }

      return response ?? null;
    },
    [
      MODEL_TABLE_ITEMS_FIELDS.selected,
      MODEL_UPDATE_ATTRIBUTES,
      handleRulesModal,
      model,
      modelRules,
      t,
      updateModel,
    ],
  );

  const handleChangeCategory = useCallback<
    React.ComponentProps<typeof TableFilters>['onSelect']
  >(
    (categoryKey: string) => {
      handleChangeRootCategory(categoryKey);
      setSearchValue('');

      const staticId = scrollMenuOptions.find(
        option => option.key === categoryKey,
      )?.staticId;

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: {
              category: staticId ?? null,
              token: modelToken,
            },
          },
        });
      }
    },
    [handleChangeRootCategory, scrollMenuOptions, sendMessage, modelToken],
  );

  const handleSelectPackageRecord = useCallback(
    async (record: PackageItem): Promise<null> => {
      if (!model) return null;

      if (!isPackageHasSelection(model, record)) {
        await updateModel(model, {
          [ModelUpdateAttributesObjectFields.commonItem]: {
            ...record,
            [Model_CommonItemFields.selected]: true,
          },
        });
        return null;
      }
      // current package flow differs from flat record's
      // we should open the modal and everything else
      // should be handled from there (check package related modal)
      handlePackageLinesModal({ option: record });
      // following the contract
      return null;
    },
    [handlePackageLinesModal, model, updateModel],
  );

  const _handleSelectRecord = useCallback(
    (record: Model_CommonItem): Promise<Model | null> => {
      const selectHandler =
        isPackage(record) && isAllowViewDetailsFeatureEnabled
          ? handleSelectPackageRecord
          : handleSelectFlatRecord;
      return selectHandler(record);
    },
    [isAllowViewDetailsFeatureEnabled, handleSelectPackageRecord, handleSelectFlatRecord],
  );

  const handleSelectRecord = useCallback(
    (record: Model_CommonItem, toSelect: boolean) => {
      // @todo: check why records contains "undefined"s
      // default selected records are "undefined"
      // removing them as anyway tey are un-handleable
      const changeHandler = toSelect ? _handleSelectRecord : _handleDeselectRecord;
      changeHandler(record);
      setLatestOption(record);
    },
    [_handleDeselectRecord, _handleSelectRecord],
  );

  const getItemImage = useCallback(
    (item: CategoryModelItem): string => {
      const imageLink = get(item, Model_CommonCategoryFields.image, '');
      const webImages = get(model, ModelFields.webImage, []);

      const [image] = webImages
        .filter(images => get(images, WebImageItemFields.imageLink) === imageLink)
        .sort(
          (a, b) =>
            get(a, WebImageItemFields.orderNumber, 0) -
            get(b, WebImageItemFields.orderNumber, 0),
        );

      return get(image, WebImageItemFields.url, '');
    },
    [model],
  );

  const memoizedTableData = useMemo(() => {
    return tableData;
  }, [tableData]);

  /* Check model to be bult */
  useEffect(() => {
    if (!model || modelStatus !== Status.Success) return;

    const modelNumber = get(model, [ModelFields.model, 0, ModelItemFields.modelNumber]);

    if (!modelNumber) {
      history.push({
        pathname: MODELS_URL,
        search: history.location.search,
      });
    }
  }, [model, history, modelStatus]);

  const categoryKey = selectedScrollMenuOption?.staticId ?? '';

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.CHANGE_SCREEN,
      data: {
        screen: 'selection',
      },
    });
  }, [sendMessage]);

  useEffect(() => {
    setLatestOption(undefined);

    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: {
            category: categoryKey,
            optionType: STREAMING_OPTION_TYPE,
            token: modelToken,
          },
        },
      });
    }
  }, [STREAMING_OPTION_TYPE, categoryKey, modelToken, pathname, sendMessage]);

  useEffect(() => {
    const tableItems = get(model, MODEL_TABLE, []) as Model_CommonItem[];
    setTableParams(tableItems);
    if (
      isStandardFeaturesFeatureEnabled &&
      typeof queryValues.standardFeaturesValue === 'boolean'
    ) {
      handleStandardFeaturesValueChange(queryValues.standardFeaturesValue);
    }
  }, [
    MODEL_TABLE,
    MODEL_TABLE_ITEMS_FIELDS.ID,
    MODEL_TABLE_ITEMS_FIELDS.selected,
    handleStandardFeaturesValueChange,
    isStandardFeaturesFeatureEnabled,
    model,
    queryValues.standardFeaturesValue,
    setTableParams,
    stepId,
  ]);

  useEffect(() => {
    const messages = get(model, ModelFields.message);
    let optionIdsWithError: OptionWithError[] = [];

    if (messages) {
      optionIdsWithError = messages
        .filter(msg => get(msg, MessageFields.code) === MessageCodeValues.error)
        .map(msg => {
          const errorDescription = get(msg, MessageFields.text, '');

          return {
            id: get(latestOption, MODEL_TABLE_ITEMS_FIELDS.ID),
            description: errorDescription,
          };
        });
    }

    addOptionWithError(optionIdsWithError);
  }, [model, latestOption, MODEL_TABLE_ITEMS_FIELDS.ID]);

  useEffect(() => {
    if (isStandardFeaturesFeatureEnabled) {
      // if not in the URL
      if (!query[URL_QUERY_PARAMS.standardFeaturesValue]) {
        setQueryParams({
          key: URL_QUERY_PARAMS.standardFeaturesValue,
          value: String(DEFAULT_SFV),
        });
      }
    } else {
      removeQueryParams(URL_QUERY_PARAMS.standardFeaturesValue);
    }
  }, [
    isStandardFeaturesFeatureEnabled,
    removeQueryParams,
    setQueryParams,
    query,
    queryValues.standardFeaturesValue,
  ]);

  useEffect(() => {
    getBackgroundImage();
  }, [getBackgroundImage]);

  useEffect(() => {
    const handleResize = () => {
      const element = document.getElementById('layout-top-section');
      if (!element) return;
      setStickyOffset(element.clientHeight);
    };

    // on init
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tableColumns = useColumns({
    handleCommentsModal: handleCommentsModal,
    handleDocumentsModal: handleDocumentsModal,
    handleViewImages,
    handleSelectRecord,
    handleEditPriceModal: handleEditPriceModal,
    handleDiscountModal: handleDiscountModal,
    handlePackageLinesModal: handlePackageLinesModal,
    stepId,
    pathname,
    isTableViewExtended,
  });

  const showModelImage = canShowBackgroundImage && isLgAndWider;

  const table = (
    <>
      <TableFilters
        scrollMenuOptions={scrollMenuOptions}
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
        onSelect={handleChangeCategory}
        prevStepDisabled={false}
        nextStepDisabled={false}
        onSearch={onSearch}
        searchValue={searchValue}
        /** @todo remove (get from BE) */
        isAllowExtendedView={isAllowExtendedViewFeatureEnabled && hasPhotoColumn}
        isTableViewExtended={isTableViewExtended}
        isStandardFeaturesFeatureEnabled={isStandardFeaturesFeatureEnabled}
        handleStandardFeaturesValueChange={handleStandardFeaturesValueChange}
        setIsTableViewExtended={setIsTableViewExtended}
        showModelImage={showModelImage}
        extraRight={
          isAllowMakeOptionalModal ? (
            <Button
              style={{ marginLeft: 'auto', marginRight: '0.625rem' }}
              variant="highlighted"
              onClick={() => handleMakeOptionalModal(true)}
              disabled={isConfigurationComplete}
            >
              {t('MODAL_MAKE_OPTIONAL')}
            </Button>
          ) : null
        }
      />
      <TableBreadcrumbs breadcrumbs={breadcrumbs} />
      {subcategories.length > 0 ? (
        <TableSubcategory
          subcategories={subcategories}
          selectSubcategory={handleSelectSubcategory}
          getItemImage={getItemImage}
        />
      ) : (
        <ScTable
          rowKey={record => get(record, Model_CommonItemFields.ID)}
          data={memoizedTableData}
          columns={tableColumns}
          rowClassName={record => (getErrorInOption(record) ? 'row-with-error' : '')}
          tableLayout={isSmAndWider ? 'auto' : 'none'}
          onRow={onRow}
          sticky={{
            // header height in px
            offsetHeader: stickyOffset,
          }}
          scroll={isSmAndWider ? { x: true } : undefined}
          pagination={memoizedTableData.length > DEFAULT_TABLE_PAGE_SIZE}
          resetKey={getResetKey([selectedScrollMenuOption?.name || ''])}
        />
      )}
    </>
  );

  const floatHolder = document.getElementById('float-holder');

  const addNewItemButton =
    isAddItemButtonVisible && floatHolder
      ? createPortal(
          <Button.ButtonAdd
            onClick={() => handleCreateOptionModal(true)}
            disabled={isConfigurationComplete}
            data-testid="dynamic-steps-add-new-item-btn"
          />,
          floatHolder,
        )
      : null;

  return (
    <Layout bg={false}>
      <Container>
        {addNewItemButton}

        <DynamicPageModals
          editPriceModalState={editPriceModalState}
          commentsModalState={commentsModalState}
          discountModalState={discountModalState}
          documentsModalState={documentsModalState}
          packageLinesModalState={packageLinesModalState}
          createOptionModalState={createOptionModalState}
          obligatoryModalState={rulesModalState}
          makeOptionalModalState={makeOptionalModalState}
        />

        <TableImageGallery
          images={carouselImages}
          visible={carouselImages.length > 0}
          onCancel={() => setCarouselImages([])}
        />
        {showModelImage ? (
          <SplitPane isFallbackTableImage={isFallback}>
            {table}
            <ModelImage image={backgroundImage} isFallback={isFallback} />
          </SplitPane>
        ) : (
          table
        )}
      </Container>
    </Layout>
  );
};
