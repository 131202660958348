import {
  PriceListFields,
  PriceListItemWithImage,
} from '@hypercharge/xdms-client/lib/types';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { useXdmsClient, XdmsClient } from 'context/xdms/XdmsClient';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';
import { StreamingEventType } from 'context/streaming/types';

type ContextValue = {
  status: Status;
  priceList: PriceListItemWithImage[];
  selectedPriceListItem: PriceListItemWithImage | null;
  getPriceList(): Promise<PriceListItemWithImage[]>;
};

const PriceListContext = React.createContext<ContextValue | undefined>(undefined);

/** PriceList = Catalog */
const PriceListProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { xdmsClient: xdmsClientUntyped } = useXdmsClient();
  const xdmsClient = xdmsClientUntyped as XdmsClient;
  const { sendMessage } = useStreaming();
  const { modelCatalogCode } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  const [status, setStatus] = useState<ContextValue['status']>(Status.Idle);
  const [priceList, setPriceList] = useState<ContextValue['priceList']>([]);
  const [selectedPriceListItem, setSelectedPriceListItem] =
    useState<ContextValue['selectedPriceListItem']>(null);

  const getPriceList = useCallback<ContextValue['getPriceList']>(async (): Promise<
    PriceListItemWithImage[]
  > => {
    try {
      setStatus(Status.Loading);
      const priceList = await xdmsClient.priceList.getListWithImages();
      setPriceList(priceList);
      setStatus(Status.Success);
      return priceList;
    } catch (e) {
      notification.requestError(e, setStatus);
    }

    return [];
  }, [xdmsClient.priceList]);

  // make sure that at any point of time
  // the selected before price list is available
  useEffect(() => {
    if (status === Status.Idle) {
      getPriceList();
    }

    const priceListItem = priceList.find(
      item => item[PriceListFields.catalog] === modelCatalogCode,
    );
    if (priceListItem) {
      setSelectedPriceListItem(priceListItem);

      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'priceList',
          data: {
            priceListName: priceListItem[PriceListFields.catalog],
            priceListImage: priceListItem[PriceListFields.imageUrl],
            priceListDescription: priceListItem[PriceListFields.catalogDescription],
            modelYear: priceListItem[PriceListFields.version],
            // @todo: map dynamically
            companyName: 'Volvo',
          },
        },
      });
    }
  }, [priceList, getPriceList, sendMessage, modelCatalogCode, status]);

  const value = useMemo(
    () => ({
      priceList,
      selectedPriceListItem,
      status,
      getPriceList,
    }),
    [priceList, selectedPriceListItem, status, getPriceList],
  );

  return <PriceListContext.Provider value={value} {...props} />;
};

const usePriceList = (): ContextValue => {
  const context = useContext(PriceListContext);

  if (context === undefined) {
    throw new Error(
      `${usePriceList.name} must be used within an ${PriceListProvider.name}`,
    );
  }

  return context;
};

export { PriceListProvider, usePriceList };
