import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { SOFT_OFFER_URL } from 'common/constants';
import { Button } from 'components/button';
import NavButtonsContainer from 'components/button/NavButtonsContainer/NavButtonsContainer';
import {
  ScButton,
  ScButtonReload,
  ScDocumentButtonIcon,
  ScDocumentReloadButtonIcon,
  ScFooterContainer,
} from './Footer.styles';
import { Dropdown } from 'components/dropdown';
/** @todo: refactor; make dropdown a regular component or get rid of it */
import {
  ScDocumentTemplateMenu,
  ScDocumentTemplateMenuItem,
  ScDocumentTemplateMenuItemButton,
} from '../../../components/documentTemplate/DocumentTemplateDropdown.styles';
import { Status } from 'utils/types';
import classNames from 'classnames';

interface Props {
  isSubmitHidden?: boolean;
  isSubmitDisabled?: boolean;
  publishButton?: {
    disabled?: boolean;
    loading?: boolean;
    onPublish?(): void;
  };
  isDocumentsVisible?: boolean;
  documentsData?: { url: string; name: string }[];
  reloadDocuments?(): void;
}

const Footer: FC<Props> = ({
  isSubmitDisabled,
  isSubmitHidden,
  publishButton,
  isDocumentsVisible,
  documentsData,
  reloadDocuments,
}) => {
  const { t } = useTranslation();

  const [reloadDocumentsStatus, setReloadDocumentsStatus] = useState<Status>(Status.Idle);

  useEffect(() => {
    setReloadDocumentsStatus(Status.Idle);
  }, [documentsData]);

  return (
    <ScFooterContainer>
      <NavButtonsContainer>
        {isDocumentsVisible && (
          <Dropdown
            trigger={['click']}
            disabled={!documentsData?.length}
            overlay={
              <ScDocumentTemplateMenu>
                {documentsData?.map(({ url, name }) => (
                  <ScDocumentTemplateMenuItem key={name}>
                    <a href={url} target="_blank" rel="noreferrer">
                      <ScDocumentTemplateMenuItemButton>
                        {name}
                      </ScDocumentTemplateMenuItemButton>
                    </a>
                  </ScDocumentTemplateMenuItem>
                ))}
              </ScDocumentTemplateMenu>
            }
          >
            <div style={{ position: 'relative' }}>
              {!documentsData?.length && (
                <ScButtonReload
                  className={classNames({
                    loading: reloadDocumentsStatus === Status.Loading,
                  })}
                  disabled={reloadDocumentsStatus === Status.Loading}
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    reloadDocuments?.();
                    setReloadDocumentsStatus(Status.Loading);
                  }}
                >
                  <ScDocumentReloadButtonIcon />
                </ScButtonReload>
              )}
              <Button variant="highlighted" disabled={!documentsData?.length}>
                <ScDocumentButtonIcon />
                {t('SOFT_OFFER_DOCUMENTS')}
              </Button>
            </div>
          </Dropdown>
        )}

        <NavLink to={`${SOFT_OFFER_URL}/list`}>
          <Button data-testid="soft-offer-form-view-table-btn">
            {t('SOFT_OFFER_VIEW_SOFT_OFFERS')}
          </Button>
        </NavLink>

        {publishButton && (
          <ScButton
            variant="highlighted"
            data-testid="soft-offer-form-publish-btn"
            onClick={publishButton.onPublish}
            disabled={publishButton.disabled}
            loading={publishButton.loading}
          >
            {t('SOFT_OFFER_PUBLISH')}
          </ScButton>
        )}

        {!isSubmitHidden && (
          <Button
            variant="highlighted"
            data-testid="soft-offer-form-create-btn"
            htmlType="submit"
            disabled={isSubmitDisabled}
          >
            {t('SOFT_OFFER_UPDATE')}
          </Button>
        )}
      </NavButtonsContainer>
    </ScFooterContainer>
  );
};

export default Footer;
