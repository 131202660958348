import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'utils/types';
import { useConfigurationCalcApi } from 'context/configuration/useConfigurationCalc';
import { useSelector } from 'react-redux';
import { configurationSelectors } from 'store';
import { Money } from 'utils/money';
import { ConfigurationTotalCalculationsFields } from 'types/vendor';
import { ScContainer, ScFloatingContainer } from './ConfigurationMarginModal.styles';
import { TableLike } from 'components/tableLike.styles';
import { Loader } from 'components/Loader';
import { Price } from 'components/Price';

interface Data {
  grossTotal: number;
  catalogPrice: number;
  ratio: number;
}

const ConfigurationMarginModalData: FC = () => {
  const { t } = useTranslation();

  const { loadConfigurationTotalsCalculations } = useConfigurationCalcApi();

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const [status, setStatus] = useState(Status.Idle);
  const [data, setData] = useState<Data | null>(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!configurationNumber) return;
    const on = true;
    setStatus(Status.Loading);
    loadConfigurationTotalsCalculations(configurationNumber)
      .then(({ response }) => {
        if (!response) {
          if (on) {
            setData(null);
          }
          return;
        }

        const grossTotal = response[ConfigurationTotalCalculationsFields.total];
        const catalogPrice =
          response[ConfigurationTotalCalculationsFields.cataloguePrice];
        const ratio = new Money(grossTotal).divide(catalogPrice).multiply(100).value;

        if (on) {
          setData({
            grossTotal,
            catalogPrice,
            ratio: Number.isNaN(ratio) ? 0 : ratio,
          });
          setStatus(Status.Success);
        }
      })
      .catch(error => {
        if (on) {
          setMessage(String(error));
          setStatus(Status.Error);
        }
      });
  }, [configurationNumber, loadConfigurationTotalsCalculations]);

  return (
    <ScContainer>
      <TableLike>
        <TableLike.colgroup>
          <TableLike.col width={'50%'} />
          <TableLike.col width={'50%'} />
        </TableLike.colgroup>

        <TableLike.tr>
          <TableLike.td>{t('CONFIGURATION_MARGIN_MODAL_GROSS_TOTAL')}</TableLike.td>
          <TableLike.td>
            <Price value={data?.grossTotal ?? 0} />
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>{t('CONFIGURATION_MARGIN_MODAL_CATALOG_PRICE')}</TableLike.td>
          <TableLike.td>
            <Price value={data?.catalogPrice ?? 0} />
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>{t('CONFIGURATION_MARGIN_MODAL_RATIO')}</TableLike.td>
          <TableLike.td>
            <Price value={data?.ratio ?? 0} percentage />
          </TableLike.td>
        </TableLike.tr>
      </TableLike>

      {status === Status.Error && (
        <ScFloatingContainer>{message || t('UNKNOWN_ERROR')}</ScFloatingContainer>
      )}
      {status === Status.Loading && (
        <ScFloatingContainer>
          <Loader />
        </ScFloatingContainer>
      )}
    </ScContainer>
  );
};

export default ConfigurationMarginModalData;
