import {
  Model_CommonItem,
  Model_CommonItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { Typography } from 'antd';
import { Modal } from 'components/modal';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { useModelApi } from 'context/model/useModelApi';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { PAGES_SETTINGS } from 'context/tableCategories/TableCategoriesProvider';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { modelSelectors, sharedSelectors } from 'store';
import { get } from 'utils';
import { defaultStepId } from 'utils/constants';
import { getModelUpdateAttributes, halfUpRound } from 'utils/format';
import { Money } from 'utils/money';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import {
  ScModalFooter,
  ScModalFooterButton,
  ScModalTitle,
} from 'components/modalLike.styles';
import { TableLike } from 'components/tableLike.styles';
import { ScPrice } from './DiscountModal.styles';
import { StreamingEventType } from 'context/streaming/types';
import { getModelCommonItemById } from 'utils/getModelCommonItemById';

export type DiscountModalCallParams = Pick<DiscountModalProps, 'option'>;

export interface DiscountModalProps {
  onClose(): void;
  option: Model_CommonItem;
  stepId?: keyof typeof PAGES_SETTINGS;
}

export const DiscountModal: FC<DiscountModalProps> = ({
  onClose,
  option,
  stepId = defaultStepId,
}) => {
  const { t } = useTranslation();
  const { updateModel } = useModelApi();
  const { sendMessage } = useStreaming();

  const { currency } = useCurrency();

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);
  const { model, status, isConfigurationComplete } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);

  const sellingPriceField = shouldShowPricesWithVAT
    ? Model_CommonItemFields.priceVat
    : Model_CommonItemFields.price;

  const discountAmountField = shouldShowPricesWithVAT
    ? Model_CommonItemFields.discountAmount_withVAT
    : Model_CommonItemFields.discountAmount;

  const recordPrice: number = get(option, sellingPriceField, 0);
  const [discount, setDiscount] = useState<number>(0);
  const [priceDiscount, setPriceDiscount] = useState<number>(0);
  const modelUpdateAttributes = getModelUpdateAttributes(
    stepId as keyof typeof PAGES_SETTINGS,
  );

  const handleAddDiscount = useCallback(async (): Promise<void> => {
    if (!option || typeof discount !== 'number') {
      return;
    }

    if (!model) return;
    const {
      status,
      messageHandled,
      response: updatedModel,
    } = await updateModel(model, {
      [modelUpdateAttributes]: {
        ...option,
        [Model_CommonItemFields.selected]: true,
      },
    });

    if (!updatedModel) return;

    const selectedOption = getModelCommonItemById(
      updatedModel,
      option[Model_CommonItemFields.ID],
    );

    if (!selectedOption) return;

    await updateModel(updatedModel, {
      [modelUpdateAttributes]: {
        ...selectedOption,
        [Model_CommonItemFields.discountPercent]: discount,
      },
    });

    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: { token: modelToken },
        },
      });
    }

    if (!messageHandled) {
      notification.openByStatus(status, {
        [Status.Success]: t('UPDATE_SUCCESSFUL'),
        [Status.Error]: t('GLOBAL_ERROR_TEXT'),
      });
    }

    onClose();
  }, [
    option,
    discount,
    model,
    updateModel,
    modelUpdateAttributes,
    sendMessage,
    modelToken,
    onClose,
    t,
  ]);

  const handleChangeDiscount = useCallback(
    (value: number): void => {
      setDiscount(value);
      if (value >= 0) {
        const discountPrice = new Money(recordPrice).applyPercent(value).value;
        setPriceDiscount(halfUpRound(discountPrice));
      }
    },
    [recordPrice, setPriceDiscount, setDiscount],
  );

  const handleChangePriceDiscount = useCallback(
    (value: number): void => {
      setPriceDiscount(value);
      if (value >= 0) {
        const discount = new Money(value).getPercent(recordPrice).value;
        setDiscount(discount);
      }
    },
    [recordPrice, setPriceDiscount, setDiscount],
  );

  useEffect(() => {
    if (option) {
      const discount = get(option, Model_CommonItemFields.discountPercent, 0);
      const discountPrice = get(option, discountAmountField, 0);
      setDiscount(+discount.toFixed(4));
      setPriceDiscount(+discountPrice.toFixed(4));
    }
  }, [option, setDiscount, setPriceDiscount, discountAmountField]);

  return (
    <Modal variant="sm" visible onCancel={onClose} center>
      <ScModalTitle>{t('CONFIG_LIST_ADD_DISCOUNT')}</ScModalTitle>

      <TableLike $fixed style={{ width: '100%' }}>
        <TableLike.colgroup>
          <TableLike.col width={'50%'} />
          <TableLike.col width={'50%'} />
        </TableLike.colgroup>

        <TableLike.tr>
          <TableLike.td>{t('DISCOUNT_MODAL_OPTION')}</TableLike.td>
          <TableLike.td>
            <Typography.Text
              style={{ width: '100%' }}
              ellipsis
              data-testid="discount-modal-item-name"
            >
              {get(option, Model_CommonItemFields.name, '')}
            </Typography.Text>
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>
            {t('DISCOUNT_MODAL_SELLING_PRICE', {
              vat: `(${
                shouldShowPricesWithVAT ? t('INCLUDING_VAT') : t('EXCLUDING_VAT')
              })`,
            })}
          </TableLike.td>
          <TableLike.td>
            <ScPrice value={get(option, sellingPriceField, 0)} />
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>{`${t('DISCOUNT')} (%)`}</TableLike.td>
          <TableLike.td>
            <InputNumberLocalized
              bordered={false}
              size="small"
              min={0}
              max={100}
              value={discount}
              defaultValue={discount}
              valueSuffix={'%'}
              symbolsAfterDecimal={4}
              onChange={event => handleChangeDiscount(event.target.value)}
              className="discountColor"
              data-testid="discount-modal-percentage-discount-input"
              disabled={isConfigurationComplete}
            />
          </TableLike.td>
        </TableLike.tr>

        <TableLike.tr>
          <TableLike.td>{`${t('DISCOUNT_MODAL_DISCOUNT', {
            vat: `(${shouldShowPricesWithVAT ? t('INCLUDING_VAT') : t('EXCLUDING_VAT')})`,
          })} (€)`}</TableLike.td>
          <TableLike.td>
            <InputNumberLocalized
              bordered={false}
              size="small"
              min={0}
              step={1}
              max={recordPrice}
              value={priceDiscount}
              defaultValue={priceDiscount}
              valuePrefix={currency}
              onChange={event => handleChangePriceDiscount(event.target.value)}
              className="discountColor"
              data-testid="discount-modal-discount-input"
              disabled={isConfigurationComplete}
            />
          </TableLike.td>
        </TableLike.tr>
      </TableLike>

      <ScModalFooter>
        <ScModalFooterButton
          onClick={onClose}
          disabled={status === Status.Loading}
          data-testid="create-option-modal-cancel-btn"
        >
          {t('FORM_CANCEL')}
        </ScModalFooterButton>
        <ScModalFooterButton
          variant="primary"
          onClick={handleAddDiscount}
          disabled={status === Status.Loading || isConfigurationComplete}
          data-testid="create-option-modal-submit-btn"
        >
          {t('SAVE')}
        </ScModalFooterButton>
      </ScModalFooter>
    </Modal>
  );
};
